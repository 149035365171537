<template>
    <div>
        <app-collapse
            :accordion="true"
            :active="['1']"
            :open="['1']"
        >
            <app-collapse-item v-for="project in projects" :key="project.id" :title="project.title">
                <questions-hierarchies-Tree
                    v-if="project.projects.length > 0"
                    v-for="child in project.projects"
                    :key="child.id"
                    :projects="child.projects"
                >
                    <!-- Table -->
                    <b-table
                        striped
                        hover
                        small
                        ref="records"
                        class="position-relative"
                        :items="child.questions"
                        responsive
                        :fields="['sno', 'description', 'actions']"
                        primary-key="id"
                        :sort-by.sync="sortBy"
                        show-empty
                        empty-text="No records found"
                        :sort-desc.sync="isSortDirDesc"
                    >
                        <!-- Column: Status -->
                        <template #cell(sno)="data">
                            <span
                                class="text-capitalize"
                            >
                                {{ data.item.id }}
                            </span>
                        </template>

                        <template #cell(description)="data">
                            <span
                                class="text-capitalize"
                            >
                                {{ data.item.description }}
                            </span>
                        </template>

                        <!-- Column: Actions -->
                        <template #cell(actions)="data">
                            <b-dropdown
                                variant="link"
                                no-caret
                                :right="$store.state.appConfig.isRTL"
                            >

                                <template #button-content>
                                <feather-icon
                                    icon="MoreVerticalIcon"
                                    size="16"
                                    class="align-middle text-body"
                                />
                                </template>
                
                                <b-dropdown-item @click="invokeUpdateForm(data.item)" :title='data.item.title'>
                                    <feather-icon icon="EditIcon" />
                                    <span class="align-middle ml-50">Edit</span>
                                </b-dropdown-item>
                
                                <b-dropdown-item @click="remove(data.item.id)">
                                    <feather-icon icon="TrashIcon" />
                                    <span class="align-middle ml-50">Delete</span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </template>
                    </b-table>
                </questions-hierarchies-Tree>

                <slot></slot>
            </app-collapse-item>
        </app-collapse>
    </div>
</template>

<script>
    import { 
        BRow, BCol,
        BTable, BFormGroup, BFormSelect, BFormSelectOption, BFormCheckbox, BFormInput, BFormDatepicker,
        BDropdown, BDropdownItem, BSpinner,
    } from 'bootstrap-vue'
    import { ref, onMounted, onUnmounted } from '@vue/composition-api'
    import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
    import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
    import store from '@/store'
    export default {
        name: 'QuestionsHierarchiesTree',
        props: {
            projects: {
                type: Array,
                required: true
            },
        },
        components: {
            BRow, BCol,
            BTable, BFormGroup, BFormSelect, BFormSelectOption, BFormCheckbox, BFormInput, BFormDatepicker,
            BDropdown, BDropdownItem, BSpinner,
            AppCollapse,
            AppCollapseItem
        },

        setup(props, context) {
            const sortBy = ref('description')
            const isSortDirDesc = ref(false)

            return {
                sortBy,
                isSortDirDesc,
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>