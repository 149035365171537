import axios from '@axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        list: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('questions', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        show: (cntx, { id }) => {
            axios.get(`questions/${id}`)
                .then(response => resolve(response))
                .catch(error => reject(error))
        },
        create: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.post('questions', params)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        update: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.put(`questions/${params.id}`, params.data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        remove: (cntx, id) => {
            return new Promise((resolve, reject) => {
                axios.delete(`questions/${id}`)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        seasons: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('seasons', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        types: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('types', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        questionnaires: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('questionnaires', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        season_questionnaires: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('season_questionnaires', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        projects: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('projects', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    }
}